<template>
  <div>
    <b-modal id="modal-signo-vitales" size="lg"
      hide-footer
      title="Enfermeria"
      :header-bg-variant="'gray'"
      :visible="visible"
      @change="$emit('change', $event)"
    >
      <template #modal-header="{ close }">
        <div class="row">
          <div class="col-sm-8">
            <div class="col-sm-12">
              <strong>Dra: </strong> Maria Ledesma
            </div>
          </div>
          <div class="col-sm-4 text-right">
            <span class="font-weight-bold align-middle" @click="close()"
              style="cursor: pointer">X</span>
          </div>
        </div>
      </template>
      <div class="row px-3">
        <div class="col-sm-4 pb-3">
          <div class="form-group">
            <label for="talla"><strong>Talla:</strong></label>
            <input type="text" id="talla" class="form-control">
          </div>
          <div class="form-group">
            <label for="talla">
              <strong>IMC</strong> <span class="font-10">(Indice Mesa Corporal)</span>
            </label>
            <input type="text" id="talla" class="form-control">
          </div>
          <div class="form-group">
            <label for="talla">
              <strong>FC</strong> <span class="font-10">(Frecuencia Cardiaca)</span>
            </label>
            <input type="text" id="talla" class="form-control">
          </div>
        </div>
        <div class="col-sm-4 pb-3">
          <div class="form-group">
            <label for="talla"><strong>Peso:</strong></label>
            <input type="text" id="talla" class="form-control">
          </div>
          <div class="form-group">
            <label for="talla">
              <strong>FR</strong> <span class="font-10">(Frecuencia Respiratoria)</span>
            </label>
            <input type="text" id="talla" class="form-control">
          </div>
          <div class="form-group">
            <label for="talla">
              <strong>SAT 02</strong> <span class="font-10">(Saturación de Oxigeno)</span>
            </label>
            <input type="text" id="talla" class="form-control">
          </div>
        </div>
        <div class="col-sm-4 pb-3">
          <div class="form-group">
            <label for=""><strong>Temperatura:</strong></label>
            <div class="row">
              <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                <label for="oral"><strong>Oral:</strong></label>
              </div>
              <div class="col-sm-8">
                <input type="text" id="oral" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">
              <strong>PA</strong> <span class="font-10">(Presión Arterial)</span>
            </label>
            <div class="row pb-2">
              <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                <label for="sistolica"><strong>Sistolica:</strong></label>
              </div>
              <div class="col-sm-8">
                <input type="text" id="sistolica" class="form-control">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                <label for="diastolica"><strong>Diastolica:</strong></label>
              </div>
              <div class="col-sm-8">
                <input type="text" id="diastolica" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'theModalSignoVitales',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.modal-header {
  display:block !important;
}
.font-10 {
  font-size: 10px;
}
.bg-gray {
  background-color: rgb(200,200,200);
}
</style>
