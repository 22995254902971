<template>
  <b-modal
    title="Modal Historial Clinico"
    size="custom-lg-max"
    hide-footer
    id="TheModalHistorialClinico"
  >
    <template #modal-header>
      <h5 class="modal-title">
        <b>Historiaa Clínica N° 34</b>
      </h5>
    </template>
    <div class="row">
      <div class="col-sm-12">
        <b-table
          class="table table-hover"
          :fields="fields"
          :items="listaTabla"
        >
        <template #cell(fecha)="item">
              {{ formatDate(item.item.fecha) }}
          </template>
          <template #cell(opciones)="item">
            <i
              class="nav-icon i-File-Download font-weight-bold text-info cursor-pointer"
              style="cursor: pointer"
              @click="openModalPrint(item.item)"
            ></i>
          </template>
        </b-table>
      </div>
    </div>
    <modalPrint
      :itemService="itemServicio"
      :selectedConsulta="selectedId"
      @open-modal="openModalPrint"
    />
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import modalPrint from './modalPrint.vue';

export default {
  name: 'TheModalHistorialClinico',
  components: {
    modalPrint,
  },
  props: {
    pacienteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'numero', label: 'N°', class: 'text-center', thClass: 'bg-light', thStyle: { width: '7%' },
        },
        {
          key: 'numero_consulta', label: 'N° C.E', class: 'text-center', thClass: 'bg-light', thStyle: { width: '8%' },
        },
        {
          key: 'tipo', label: 'Tipo', class: 'text-center', thClass: 'bg-light', thStyle: { width: '11%' },
        },
        {
          key: 'fecha', label: 'Fecha', class: 'text-center', thClass: 'bg-light', thStyle: { width: '9%' },
        },
        {
          key: 'medico', label: 'Médico', class: 'text-center', thClass: 'bg-light', thStyle: { width: '21%' },
        },
        {
          key: 'motivo', label: 'Motivo', class: 'text-center', thClass: 'bg-light', thStyle: { width: '20%' },
        },
        {
          key: 'diagnostico', label: 'Diagnóstico', class: 'text-center', thClass: 'bg-light', thStyle: { width: '20%' },
        },
        {
          key: 'opciones', label: '', class: 'text-center', thClass: 'bg-light', thStyle: { width: '4%' },
        },
      ],
      selectedId: null,
      listaTabla: [
      ],
      itemServicio: null,
    };
  },
  created() {
    this.listarRegistros();
  },
  methods: {
    async listarRegistros() {
      try {
        const RESPONSE = await axios.get(`/clinic/consultation/patient/historia_clinica/${this.pacienteId}`);
        this.listaTabla = RESPONSE.data.data.sort((a, b) => b.numero - a.numero);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    formatDate(fecha) {
      if (!fecha) return '';
      const [year, month, day] = fecha.split('-');
      return `${day}/${month}/${year}`;
    },
    async getServicioComplementario(item) {
      try {
        const RESPONSE = await axios.get(`clinic/consulta_externa/${item}/get_categoria_servicio_complementarios`);
        this.itemServicio = RESPONSE.data.data.categoria_servicio_complementarios;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    openModalPrint(item) {
      this.selectedId = item;
      this.getServicioComplementario(item.consulta_id).then(() => {
        this.$bvModal.show('modalPrintConsultaExterna');
      });
    },
  },
};
</script>
