<template>
  <b-modal
  id="theModalTratamientoProceso" hide-footer size="lg">
  <template slot="modal-title">
    <span style="font-size: 11px;" v-if="reconsulta">
      <strong>Dr:</strong>
        {{ reconsulta[0].nombre }}
        <br>
      <strong>Fecha: </strong>
        {{ reconsulta[0].fecha }} - Hora: {{ reconsulta[0].hora }}
    </span>
  </template>
    <form >
      <div class="modal-body" v-if="reconsulta">
        <label class="col-sm-4  col-form-label">Motivo Consulta: </label>
        <div class="row form-group">
          <div class="col-sm-10 col-lg-12">
            <input type="text" class="form-control" disabled
              v-model="reconsulta[0].motivo_consulta">
          </div>
        </div>
        <label class="col-sm-4  col-form-label">Diagnotico 1: </label>
        <div class="row form-group">
          <div class="col-sm-10 col-lg-12">
            <input type="text" class="form-control" disabled
              v-model="reconsulta[0].diagnostico1">
          </div>
        </div>
        <label class="col-sm-4 col-form-label">Tratamiento: </label>
        <div class="row form-group">
          <div class="col-sm-12 col-lg-12">
            <textarea name="" id="" class="form-control" cols="30" rows="5"
            disabled v-model="reconsulta[0].tratamiento"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <span style="color: green; cursor: pointer;" @click="imprimirHC(consulta.id)">Ver HC en PDF</span>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'TheModalTratamientoProceso',
  data() {
    return {};
  },
  methods: {
    async imprimirHC(consultaID) {
      let RESPONSE;
      try {
        RESPONSE = await axios.get(`clinic/consultation/patient/historia_clinica/print/${consultaID}`, {
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    ...mapState('ConsultaExternaEdit', [
      'consulta',
    ]),
    ...mapState('theModalTratamientoProceso', [
      'reconsulta',
    ]),
  },
};

</script>
