<template>
  <b-modal title="Antecedentes" size="lg"
    hide-footer
    id='theModalAntecedente'>
    <div class="modal-body">
      <div>
        <!-- Antecedentes Personales No patologicos -->
        <div class="form-row">
          <div class="col-sm-12 mb-2" style="background: #bbb;">
            <label for=""> <strong> Antecedentes Personales NO patologicos </strong></label>
          </div>
          <div class="col-sm-12">
            <div class="form-group row">
              <label for="dieta" class="col-sm-1 col-form-label text-right">
                Dieta:
              </label>
              <div class="col-sm-3">
                <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoDietas"
                  :show-labels="false"
                  v-model="tipoDietaSelected"
                  >
                  </multiselect2>
              </div>
              <label for="tabaco" class="col-sm-1 col-form-label text-right">
                Tabaco:
              </label>
              <div class="col-sm-3">
                <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoTabacos"
                  :show-labels="false"
                  v-model="tipoTabacoSelected"
                  >
                  </multiselect2>
              </div>
              <label for="fecha_inicio" class="col-sm-1 col-form-label text-right">Inicio</label>
              <div class="col-sm-3">
                <date-picker
                  v-model="fechaInicio"
                  lang="es"
                  format="DD/MM/YYYY"
                  type="date"
                  value-type="date"
                  class="col-sm-12"
                ></date-picker>
              </div>
            </div>
            <div class="form-group row">
              <label for="vivienda" class="col-sm-1 col-form-label text-right">
                Vivienda:
              </label>
              <div class="col-sm-3">
                <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoViviendas"
                  :show-labels="false"
                  v-model="tipoViviendaSelected"
                  >
                  </multiselect2>
              </div>
              <label for="alcohol" class="col-sm-1 col-form-label text-right">
                Alcohol:
              </label>
              <div class="col-sm-3">
                <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoAlcohos"
                  :show-labels="false"
                  v-model="tipoAlcohosSelected"
                  >
                  </multiselect2>
              </div>
              <label for="fecha_inicio_2" class="col-sm-1 col-form-label text-right">Inicio</label>
              <div class="col-sm-3">
                <date-picker
                  v-model="fechaInicio2"
                  lang="es"
                  format="DD/MM/YYYY"
                  type="date"
                  value-type="date"
                  class="col-sm-12"
                ></date-picker>
              </div>
            </div>
            <div class="form-group row">
              <label for="uso_medicamentos" class="col-sm-3 col-form-label text-right">
                Uso de Medicamentos:
              </label>
              <div class="col-sm-3">
                <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoMedicamentos"
                  :show-labels="false"
                  v-model="tipoMedicamentoSelected"
                  >
                  </multiselect2>
              </div>
              <label for="uso_medicamentos" class="col-sm-3 col-form-label text-right">
                Riesgos Ocupacional:
              </label>
              <div class="col-sm-3">
                <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoOcupacions"
                  :show-labels="false"
                  v-model="tipoOcupacionSelected"
                  >
                  </multiselect2>
              </div>
            </div>
            <div class="form-group row">
              <label for="actividad_fisica" class="col-sm-3 col-form-label text-right">
                Actividad Fisica:
              </label>
              <div class="col-sm-3">
                <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoActividadFisicas"
                  :show-labels="false"
                  v-model="tipoActividadFisicaSelected"
                  >
                  </multiselect2>
              </div>
              <label for="actividad_fisica" class="col-sm-3 col-form-label text-right">
                Riesgo Psicosocial:
              </label>
              <div class="col-sm-3">
                <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoPsicosocials"
                  :show-labels="false"
                  v-model="tipoPsicosocialSelected"
                  >
                  </multiselect2>
              </div>
            </div>
          </div>
        </div>
        <!-- ANTECEDENTES PERSONALES PATOLOGICOS -->
        <div class="form-row">
          <div class="col-sm-12 mb-2" style="background: antiquewhite;">
            <label for=""> <strong> Antecedentes Personales Patologicos </strong></label>
          </div>
          <div class="form-row">
          <label for="" style="color: red;">Factor de Riesgo y Alergias</label>
          <div class="col-sm-12">
            <div class="form-group row">
                <label for="cardivasculares_generales" class="col-sm-3 col-form-label text-right">
                    Cardiovasculares/Generales:
                </label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="cardiovasculares">
                </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group row">
                <label for="alergias" class="col-sm-3 col-form-label text-right">
                    Alergias:
                </label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="alergias">
                </div>
            </div>
          </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-sm-6">
            <div class="row mb-2" style="margin: auto; background: gray;">
              <div class="col-sm-6">
                <strong> <label for="patologias"> Patologias</label> </strong>
              </div>
              <div class="col-sm-6" style="text-align: end;">
                <button class="btn btn-success" type="button" @click="addPatologias">+</button>
              </div>
            </div>
            <div class="col-sm-12 mb-2" style="padding: 3px; background: #eee;"
              v-for="(patologia, index) in patologias" :key="index">
              <div v-if="patologias.length > 1" class="col-sm-1">
                  <i class="nav-icon i-Close-Window font-weight-bold text-danger cursor-pointer"
                  @click="eliminarPatologia(index)" title="eliminar"></i>
              </div>
              <div class="form-group row">
                <label for="tipo" class="col-sm-3 col-form-label text-right">
                  Tipo:
                </label>
                <div class="col-sm-9">
                  <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoPatologias"
                  :show-labels="false"
                  v-model="patologia.tipoPatologiaSelected"
                  >
                  </multiselect2>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-12">
                  <input type="text" class="form-control" placeholder="Descripcion"
                    v-model="patologia.descripcion">
                    <span style="font-size: 10px;">{{ patologia.fecha }} </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-2" style="margin: auto; background: gray;">
              <div class="col-sm-6">
                <strong> <label for="patologias"> Cirugias</label> </strong>
              </div>
              <div class="col-sm-6" style="text-align: end;">
                <button class="btn btn-success" type="button" @click="addCirugias">+</button>
              </div>
            </div>
            <div class="col-sm-12 mb-2" style="padding: 3px; background: #eee;"
              v-for="(cirugia, index) in cirugias" :key="index">
              <div v-if="cirugia.length > 1" class="col-sm-1">
                  <i class="nav-icon i-Close-Window font-weight-bold text-danger cursor-pointer"
                  @click="eliminarCirugia(index)" title="eliminar"></i>
              </div>
              <div class="form-group row mb-2">
                <label for="tipo" class="col-sm-3 col-form-label text-right">
                  Tipo:
                </label>
                <div class="col-sm-9">
                  <multiselect2
                  track-by="nombre"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoCirugias"
                  :show-labels="false"
                  v-model="cirugia.tipoCirugiaSelected"
                  >
                  </multiselect2>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-12">
                  <input type="text" class="form-control" placeholder="Descripcion"
                  v-model="cirugia.descripcion">
                  <span style="font-size: 10px;">
                    {{ cirugia.fecha }} - Dr Mario Dalas(INT N°: 122/23)
                  </span>
                  <!-- <span style="font-size: 10px;">
                    11/10/23 - Dr Mario Dalas(INT N°: 122/23)
                  </span> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mb-2">
            <label class="col-sm-1 col-form-label text-right">Otros: </label>
            <input type="text" class="form-control" v-model="descripcionOtros">
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment/moment';
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Multiselect2 from '@/components/util/vue-multiselect2';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'TheModalAntecedente',
  data() {
    return {
    };
  },
  components: {
    Multiselect2,
    DatePicker,
  },
  methods: {
    cerrarModal() {
      this.$bvModal.hide('theModalAntecedente');
    },
    ...mapActions('theModalAntecedente', [
      'resetTheModalAntecedente',
    ]),
    addPatologias() {
      const PATOLOGIA = {
        key: `P-${new Date().getTime()}`,
        id: 0,
        tipoPatologiaSelected: null,
        descripcion: null,
        fecha: moment().format('DD/MM/YYYY'),
      };
      this.patologias.push(PATOLOGIA);
    },
    addCirugias() {
      const CIRUGIA = {
        key: `C-${new Date().getTime()}`,
        id: 0,
        tipoCirugiaSelected: null,
        descripcion: null,
        fecha: moment().format('DD/MM/YYYY'),
      };
      this.cirugias.push(CIRUGIA);
    },
    eliminarPatologia(index) {
      this.patologias.splice(index, 1);
    },
    eliminarCirugia(index) {
      this.cirugias.splice(index, 1);
    },
  },
  computed: {
    ...mapState('theModalAntecedente', [
      'tipoActividadFisicas',
      'tipoAlcohos',
      'tipoCirugias',
      'tipoDietas',
      'tipoMedicamentos',
      'tipoOcupacions',
      'tipoPatologias',
      'tipoTabacos',
      'tipoViviendas',
      'tipoPsicosocials',
    ]),
    ...mapFields('theModalAntecedente', [
      'tipoActividadFisicaSelected',
      'tipoAlcohosSelected',
      'tipoDietaSelected',
      'tipoMedicamentoSelected',
      'tipoOcupacionSelected',
      'tipoPsicosocialSelected',
      'tipoTabacoSelected',
      'tipoViviendaSelected',
      'fechaInicio',
      'fechaInicio2',
      'cardiovasculares',
      'alergias',
      'descripcionOtros',
      'patologias',
      'cirugias',
    ]),
  },
  beforeDestroy() {
    this.resetTheModalAntecedente();
  },
};
</script>
