<template>
  <div class="col-sm-12 px-0">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th width="2%"  class="th-custom-color text-center">N°</th>
          <th width="10%" class="th-custom-color text-center">Cantidad</th>
          <th width="40%" class="th-custom-color text-center">Nombre</th>
          <th width="20%" class="th-custom-color text-center">Concentrado</th>
          <th width="10%" class="th-custom-color text-center">Via</th>
          <th width="15%" class="th-custom-color text-center">Indicacion</th>
          <th width="3%" class="th-custom-color text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in listaTabla" :key="index">
          <td class="text-center"> {{ item.numero }} </td>
          <td class="text-center">{{item.cantidad}}</td>
          <td class="text-center">{{item.medicamento}}</td>
          <td class="text-center"> {{ item.descripcion }} </td>
          <td class="text-center">{{item.via}}</td>
          <td class="text-center">{{item.nombre}}</td>
          <td class="text-center">
            <i
              class="nav-icon i-Close-Window font-weight-bold text-danger"
              style="cursor: pointer"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SectionTableAndAddPharmacies',
  props: {
    listaTabla: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    getReverseListaTabla() {
      const copyListatABLA = JSON.parse(JSON.stringify(this.listaTabla));
      return copyListatABLA.reverse();
    },
  },
};
</script>
