<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div>
      <div class="row mr-0">
        <div class="col-sm-3">
          <div class="breadcrumb">
            <h1>
              Consulta Externa
              <span class="h4 pl-2" v-if="consulta">
                N°: {{consulta.numero}}
              </span>
            </h1>
          </div>
        </div>
        <transition name="fade">
          <div class="col-sm-6 pl-0 navbar-reubicado" v-if="paciente">
            <div class="row mx-0">
              <div class="col-sm-3 py-2 bg-sky-light text-white border-left border-white">
                <b>Edad:</b> {{ paciente.edad }} años
              </div>
              <div v-if="paciente.tipo_seguro === 'Conjunto'"
                class="col-sm-4 px-1 py-2 bg-sky-light text-white"
              >
                <b>Seguro:</b> {{ paciente.cobertura }}
                <span v-if="paciente.tipo_familiar">
                  ({{ paciente.tipo_familiar }})
                </span>
              </div>
              <div
                v-if="paciente.numero_poliza || paciente.cod_asegurado"
                class="col-sm-3 px-1 py-2 bg-sky-light text-white"
              >
                <template v-if="paciente.numero_poliza">
                  <b>N° Poliza: </b> {{ paciente.numero_poliza }}
                </template>
                <template v-else>
                  <b>N° Cod.:</b>{{paciente.cod_asegurado}}
                </template>
              </div>
            </div>
          </div>
        </transition>
        <div v-if="showButton" class="col-sm-3">
          <div class="row">
            <div class="col-sm-7 text-right pr-0">
              <label class="col-form-label">La consulta se cerrara en:</label>
            </div>
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control text-center text-20 text-danger border-danger"
                v-model="tiempoCierre"
                readonly
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12">
          <div class="card text-left">
            <div class="card-header">
              <div class="row">
                <div class="col-sm-5">
                  <router-link :to="{ name: 'consulta-externa-paciente',
                    params: {id: (encrypt(pacienteDto.medico_id))}}">
                    <button class="btn btn-white border border-light mr-2">
                      Regresar
                    </button>
                  </router-link>
                  <button type="submit"
                    class="btn btn-dark mr-2"
                    @click="storeConsultaExterna(false)"
                  >
                    Guardar
                  </button>
                  <button class="btn bg-custom-green mr-2"
                    @click="storeConsultaExterna(true)">Guardar y Cerrar</button>
                </div>
                <div class="col-sm-4 info-tratamiento-paciente" v-show="showReconsulta">
                  <div class="d-flex p-2 background-title">
                    <div class="font-weight-bold">
                      <span style="white-space: nowrap;">
                        El paciente tiene un tratamiento en proceso
                        <strong style="color: red; cursor: pointer;"
                          @click="openModalTratamientoProceso"> Ver </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="row pt-3">
                    <div class="col d-flex"><strong>Fecha: </strong>
                      <div class="px-3">
                        {{ pacienteDto.fecha }} - Iniciado {{ timeFormatHHMM(pacienteDto.hora) }}
                      </div>
                    </div>
                  </div>
                  <div class="row pb-3" v-if="paciente">
                    <div class="col-12"><strong>Paciente:</strong>
                      {{ paciente.full_name }}
                      ({{ paciente.edad }} años) - N° HC: {{ paciente.numero }}
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="float-right">
                      <button v-if="showButton" class="btn btn-default mr-2">
                        Historial Antecedente
                      </button>
                      <button
                        class="btn btn-default mr-2"
                        @click="openModalHistorialClinico"
                      >
                        Historial Clinico
                      </button>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-inline pt-2">
                    <button class="btn bg-custom-green mr-2"
                    v-if="tipoConsulta === 1"
                    :disabled="consultaCierre ? false : true"
                    :style="{ cursor: 'default' }">
                      Consulta
                    </button>
                    <button class="btn btnOrange mr-2"
                    :style="{ cursor: 'default' }"
                    v-if="tipoConsulta === 2">
                      Reconsulta
                    </button>
                    <button class="btn btn-default mr-2" v-if="hasFuncionalidadEnfermeria"
                      @click="openModalSignoVitales">
                      Signos Vitales
                    </button>
                    <button class="btn mr-2"
                    :class="antecedente ? 'btn-success': 'btnOrange'"
                    @click="openModalAntecedente"
                    disabled="isDisabled">
                      Antecedentes
                    </button>
                  </div>
                  <div class="form-group pt-2">
                    <b><font size=3>{{titleMotivoConsulta? titleMotivoConsulta: 'S/N'}}:</font></b>
                    <textarea name="" id="motivoConsulta"
                      cols="10" rows="3" class="form-control"
                      v-model="motivoConsulta">
                    </textarea>
                  </div>
                  <div class="form-group pt-2">
                    <b><font size=3>
                      {{titleEnfermedadActual? titleEnfermedadActual: 'S/N'}}:
                      </font>
                    </b>
                    <textarea name="" cols="10"
                      rows="8" class="form-control"
                      v-model="enfermedadActual">
                    </textarea>
                  </div>
                  <div
                    class="form-group pt-2"
                  >
                    <b><font size=3>{{titleExamenFisico? titleExamenFisico: 'S/N'}}:</font></b>
                    <textarea name="" cols="10"
                      rows="8" class="form-control"
                      v-model="examenFisico">
                    </textarea>
                  </div>
                  <div
                    v-if="cantidad_diagnostico > 2"
                    class="form-group pt-2"
                  >
                    <b><font size=3>{{titleTratamiento? titleTratamiento: 'S/N'}}:</font></b>
                    <textarea name="" cols="10"
                      rows="8" class="form-control"
                      v-model="tratamiento">
                    </textarea>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div
                    v-if="cantidad_diagnostico >= 1"
                    class="form-group mt-2"
                  >
                  <b-card class="border border-light">
                    <b>Diagnóstico: </b><span class="text-danger">(Obligatorio)</span>
                    <div class="row mt-1 mb-2">
                      <ComboDiagnostic
                      :id="'comboDiagnostic'"
                      :diagnosticos="listaClasificacionCIEFiltrada"
                      :diagnostico-inicial="diagnostico1Selected"
                      @search="filtrarDiagnosticos"
                      @addDiagnostico="(diagnostico) => guardarDiagnosticoSeleccionado(diagnostico, 1)"
                    />
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          id="inputDiagnostico"
                          v-model="descripcionDiagnostico1"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </b-card>
                  </div>
                  <div
                    v-if="cantidad_diagnostico >= 2"
                    class="form-group mt-4"
                  >
                  <b-card class="border border-light">
                    <b>Diagnóstico 2: </b><span class="text-danger">(Obligatorio)</span>
                    <div class="row mt-1 mb-2">
                      <ComboDiagnostic
                      :id="'comboDiagnostic'"
                      :diagnosticos="listaClasificacionCIEFiltrada"
                      :diagnostico-inicial="diagnostico2Selected"
                      @search="filtrarDiagnosticos"
                      @addDiagnostico="(diagnostico) => guardarDiagnosticoSeleccionado(diagnostico, 2)"                    />
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          id="inputDiagnostico"
                          v-model="descripcionDiagnostico2"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </b-card>
                  </div>
                  <div
                    v-if="cantidad_diagnostico >= 3"
                    class="form-group mt-4"
                  >
                  <b-card class="border border-light">
                    <b>Diagnóstico 3: </b><span class="text-danger">(Obligatorio)</span>
                    <div class="row mt-1 mb-2">
                      <ComboDiagnostic
                      :id="'comboDiagnostic'"
                      :diagnosticos="listaClasificacionCIEFiltrada"
                      :diagnostico-inicial="diagnostico3Selected"
                      @search="filtrarDiagnosticos"
                       @addDiagnostico="(diagnostico) => guardarDiagnosticoSeleccionado(diagnostico, 3)"
                    />
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          id="inputDiagnostico"
                          v-model="descripcionDiagnostico3"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </b-card>
                  </div>
                  <div
                    v-if="cantidad_diagnostico >= 4"
                    class="form-group mt-4"
                  >
                  <b-card class="border border-light">
                    <b>Diagnóstico 4: </b><span class="text-danger">(Obligatorio)</span>
                    <div class="row mt-1 mb-2">
                      <ComboDiagnostic
                      :id="'comboDiagnostic'"
                      :diagnosticos="listaClasificacionCIEFiltrada"
                      :diagnostico-inicial="diagnostico4Selected"
                      @search="filtrarDiagnosticos"
                      @addDiagnostico="(diagnostico) => guardarDiagnosticoSeleccionado(diagnostico, 4)"
                    />
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          id="inputDiagnostico"
                          v-model="descripcionDiagnostico4"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </b-card>
                  </div>
                  <div
                    v-if="cantidad_diagnostico <= 2"
                    class="form-group pt-1"
                  >
                    <b><font size=3>{{titleTratamiento? titleTratamiento: 'S/N'}}:</font></b>
                    <textarea
                      cols="10"
                      rows="8"
                      class="form-control"
                      v-model="tratamiento"
                    >
                    </textarea>
                  </div>
                  <div class="form-group mt-4" style="background: #dee2e6;" v-show="showReconsulta">
                    <div class="card-body border border-light">
                      <div class="form-inline pb-3">
                        <div class="pr-4" style="color: green;">
                          Resultado de Servicios Complementarios:
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <button class="btn btn-secondary mr-2">Laboratorio</button>
                          <button class="btn btn-secondary mr-2">Rayos X</button>
                          <button class="btn btn-secondary mr-2">Ecografia</button>
                          <button class="btn btn-secondary mr-2">Fisioterapia</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 pt-3">
                  <TheServiciosComplementarios
                    :reconsulta="tipoConsulta === 2 ? false : true"
                    :title="titleServicioComplementario"
                    :itemList="parametroServicioComplementarioList"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheModalAntecedente/>
    <TheModalTratamientoProceso v-if="consulta"/>
    <TheModaSignoVitales v-model="showEnfermeria"/>
    <ModalHistorialClinico :pacienteId="pacienteDto.paciente_id"/>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapActions, mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';
import TheServiciosComplementarios from '../components/TheServiciosComplementarios.vue';
import TheModalAntecedente from '../components/TheModalAntecedente.vue';
import TheModalTratamientoProceso from '../components/TheModalTratamientoProceso.vue';
import TheModaSignoVitales from '../components/TheModaSignoVitales.vue';
import ModalHistorialClinico from '../components/TheModalHistorialClinico.vue';
import ComboDiagnostic from '../../tratamiento/components/TheDiagnostic.vue';

export default {
  name: 'Consulta-Externa',
  data() {
    return {
      tipoConsulta: 1,
      showEnfermeria: false,
      showReconsulta: false,
      isLoading: true,
      pacienteDto: null,
      titleMotivoConsulta: 'S/N',
      titleEnfermedadActual: 'S/N',
      titleExamenFisico: 'S/N',
      titleTratamiento: 'S/N',
      titleServicioComplementario: 'S/N',
      parametroServicioComplementarioList: [],
      tiempoCierre: '59:00', // funciona como mm:ss
      timerInterval: null,
      showButton: false,
      listaClasificacionCIEFiltrada: [],
      clasificacionCIESeleccionado: null,
    };
  },
  async created() {
    const { params } = this.$route;
    this.getParametrosConsultaExterna();
    await this.edit(atob(params.id));
    this.procesarClasificacionCIE(this.clasificacionCies);
    this.pacienteDto = JSON.parse(localStorage.getItem('Paciente-Consulta-Externa'));
    this.tipoConsulta = this.consulta.tipo_consulta_id;
    this.reconsultation(this.tipoConsulta);
    this.isLoading = false;
    this.timerInterval = setInterval(() => {
      const a = this.tiempoCierre.split(':');
      const totalSeconds = ((+a[0]) * 60 + (+a[1])) - 1;
      const mm = Math.floor(totalSeconds / 60);
      let ss = totalSeconds % 60;
      if (ss < 10) {
        ss = `0${ss}`;
      }
      this.tiempoCierre = `${mm}:${ss}`;
      if (totalSeconds === 0) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  },
  beforeDestroy() {
    this.resetState();
    clearInterval(this.timerInterval);
  },
  components: {
    TheServiciosComplementarios,
    TheModalAntecedente,
    TheModalTratamientoProceso,
    TheModaSignoVitales,
    ModalHistorialClinico,
    ComboDiagnostic,
  },
  methods: {
    async getParametrosConsultaExterna() {
      try {
        const response = await axios.get('/clinic/servicio_complementario/nombre_parametro_servicio_consulta/create');
        const parametro1 = response.data.data.nombre_parametro_servicio_consulta;
        const parametro2 = response.data.data.nombre_parametro_servicio_complementario;
        if (parametro1.length !== 0) {
          const [data] = parametro1;
          this.titleMotivoConsulta = data.motivo_consulta;
          this.titleEnfermedadActual = data.enfermedad_actual;
          this.titleExamenFisico = data.examen_fisico;
          this.titleTratamiento = data.tratamiento;
          this.titleServicioComplementario = data.servicio_complementario;
        }
        if (parametro2.length !== 0) {
          this.parametroServicioComplementarioList = parametro2;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    openModalAntecedente() {
      this.$bvModal.show('theModalAntecedente');
    },
    async openModalTratamientoProceso() {
      const OK_GET_DATA_RECONSULTA = await this.getDataReconsultaTratamiento(this.consulta);
      if (OK_GET_DATA_RECONSULTA) {
        this.$bvModal.show('theModalTratamientoProceso');
      }
    },
    openModalSignoVitales() {
      this.showEnfermeria = true;
    },
    openModalHistorialClinico() {
      this.$bvModal.show('TheModalHistorialClinico', {
        props: {
          pacienteId: this.pacienteDto.paciente_id,
        },
      });
    },
    reconsultation(tipo) {
      if (tipo === 2) {
        this.showReconsulta = true;
      }
    },
    timeFormatHHMM(hora) {
      return moment(hora, 'HH:mm:ss').format('HH:mm');
    },
    ...mapActions('ConsultaExternaEdit', [
      'edit',
      'resetState',
      'create',
      'store',
    ]),
    ...mapActions('theModalTratamientoProceso', [
      'getDataReconsultaTratamiento',
    ]),
    async storeConsultaExterna(_cierre) {
      // const { params } = this.$route;
      const DTO = {
        paciente_id: this.paciente.id,
        medico_id: this.pacienteDto.medico_id,
        venta_id: this.pacienteDto.venta_id,
        cierre: _cierre,
        servicios_complementarios: this.clearParamServicioComplementario(),
      };
      await this.store(DTO);
      if (_cierre) {
        this.$router.push({ name: 'consulta-externa-paciente', params: { id: this.encrypt(this.pacienteDto.medico_id), stateSelected: this.encrypt(2) } });
      } else {
        this.$router.push({ name: 'consulta-externa-index' });
      }
    },
    encrypt(id) {
      return btoa(id);
    },
    clearParamServicioComplementario() {
      this.lisServiciosComplementarios.forEach((e, index) => {
        delete this.lisServiciosComplementarios[index].numero;
        delete this.lisServiciosComplementarios[index].categoria;
        delete this.lisServiciosComplementarios[index].numero_interno;
      });
      return this.lisServiciosComplementarios;
    },
    seleccionarDiagnostico(lista, diagnosticoId, subClasificacionId) {
      return lista.find(
        (item) => item.id === diagnosticoId && (subClasificacionId ? item.id_sub === subClasificacionId : true),
      ) || null;
    },
    procesarClasificacionCIE(responseData) {
      const listaOrganizada = [];

      responseData.forEach((diagnostico) => {
        if (diagnostico.sub_clasificacion_cie && diagnostico.sub_clasificacion_cie.length > 0) {
          diagnostico.sub_clasificacion_cie.forEach((sub) => {
            listaOrganizada.push({
              id: diagnostico.id,
              codigo: diagnostico.codigo,
              nombre: diagnostico.nombre,
              id_sub: sub.id,
              sub_codigo: sub.codigo,
              sub_nombre: sub.nombre,
            });
          });
        } else {
          listaOrganizada.push({
            id: diagnostico.id,
            codigo: diagnostico.codigo,
            nombre: diagnostico.nombre,
            id_sub: null,
            sub_codigo: null,
            sub_nombre: null,
          });
        }
      });

      this.listaClasificacionCIE = listaOrganizada;
      this.listaClasificacionCIEFiltrada = listaOrganizada;

      // Usar la función externa para seleccionar los diagnósticos
      this.diagnostico1Selected = this.seleccionarDiagnostico(listaOrganizada, this.diagnostico1Selected, this.subclasificacion_cie1_id);
      this.diagnostico2Selected = this.seleccionarDiagnostico(listaOrganizada, this.diagnostico2Selected, this.subclasificacion_cie2_id);
      this.diagnostico3Selected = this.seleccionarDiagnostico(listaOrganizada, this.diagnostico3Selected, this.subclasificacion_cie3_id);
      this.diagnostico4Selected = this.seleccionarDiagnostico(listaOrganizada, this.diagnostico4Selected, this.subclasificacion_cie4_id);
    },
    filtrarDiagnosticos(query) {
      if (!query) {
        this.listaClasificacionCIEFiltrada = this.listaClasificacionCIE;
      } else {
        const queryLower = query.toLowerCase();
        this.listaClasificacionCIEFiltrada = this.listaClasificacionCIE.filter(
          (item) => item.codigo.toLowerCase().includes(queryLower)
            || item.nombre.toLowerCase().includes(queryLower)
            || (item.sub_codigo && item.sub_codigo.toLowerCase().includes(queryLower))
            || (item.sub_nombre && item.sub_nombre.toLowerCase().includes(queryLower)),
        );
      }
    },

    guardarDiagnosticoSeleccionado(diagnostico, indice) {
      if (diagnostico) {
        this.clasificacionCIESeleccionado = diagnostico;
        this[`diagnostico${indice}Selected`] = this.clasificacionCIESeleccionado;
        this[`sub_clasificacion_cie${indice}`] = this[`diagnostico${indice}Selected`].id_sub;
      } else {
        this.clasificacionCIESeleccionado = null;
        this[`diagnostico${indice}Selected`] = null;
        this[`sub_clasificacion_cie${indice}`] = null;
      }
    },
  },
  computed: {
    ...mapState('ConsultaExternaEdit', [
      'clasificacionCies',
      'nombreDiagnostico',
      'consultaCierre',
      'antecedente',
      'consulta',
      'paciente',
    ]),
    ...mapFields('ConsultaExternaEdit', [
      'diagnostico1Selected',
      'diagnostico2Selected',
      'diagnostico3Selected',
      'diagnostico4Selected',
      'tratamiento',
      'examenFisico',
      'enfermedadActual',
      'motivoConsulta',
      'descripcionDiagnostico1',
      'descripcionDiagnostico2',
      'descripcionDiagnostico3',
      'descripcionDiagnostico4',
      'subclasificacion_cie1_id',
      'subclasificacion_cie2_id',
      'subclasificacion_cie3_id',
      'subclasificacion_cie4_id',
      'cantidad_diagnostico',
      'lisServiciosComplementarios',
    ]),
    ...mapGetters('main', [
      'hasFuncionalidadEnfermeria',
    ]),
  },
};
</script>

<style scoped>
.btnOrange {
  color: white;
  background-color: #F4B375;
}
.info-tratamiento-paciente {
  position: relative;
  top: -48px;
  height: 60px;
  right: 60px;
}
.background-title {
  background: yellow;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(18%, -32px);
  white-space: nowrap;
}
</style>
